import React from "react";
import { Helmet } from 'react-helmet'
import * as Icon from 'react-feather';
import { Container, Row, Col } from 'react-bootstrap';

import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

import Layout from '../../components/layout';

import * as planYourBookStyles from './plan-your-book.module.scss';

import heroImg from '../../images/plan-your-book/girl-hero@2x.png'
import starcastingImg from '../../images/plan-your-book/starcasting@2x.png'
import storyStartImg from '../../images/plan-your-book/story-start@2x.png'
import winnerImg from '../../images/plan-your-book/superhero-winner@2x.png'
import feedbackImg from '../../images/plan-your-book/writing-feedback@2x.png'
import booksImg from '../../images/plan-your-book/book-covers@2x.jpg'

const PlanYourBookPage=({ location }) => {

    return(
        <>
            <Helmet>
                <title>BoomWriter UK: Plan Your Book</title>
                <meta name="description" content="Publishing a book with your class is easy on BoomWriter UK." />
            </Helmet>
            <Layout location={location}>
                <Container fluid>
                    <Row className={`hero ${planYourBookStyles.hero}`}>
                        <Container className="d-md-none layer-up text-center">
                            <h1>Plan Your Book</h1>
                            <p>We have a range of different packs covering a variety of different types of writing. They all follow the same basic formula for success.</p>
                            <a href={process.env.TEACHER_SIGNUP_WRITE_URL} className="reversed-btn mt-1 mb-1 d-inline-block">Purchase your pack</a>
                        </Container>
                    </Row>
                    <Row className={`d-none d-md-flex ${planYourBookStyles.heroContent}`}>
                        <Container className="layer-up">
                            <Row className="align-items-center">
                                <Col xs={{}} xl={5} className="d-none d-md-block">
                                    <h1 className="sentence-case">Plan Your Book</h1>
                                    <p>We have a range of different packs covering a variety of different types of writing. They all follow the same basic formula for success.</p>
                                    <a href={process.env.TEACHER_SIGNUP_WRITE_URL} className="primary-btn mt-1 d-inline-block">Purchase your pack</a>
                                </Col>
                                <Col className="text-align-center ml-xl-1">
                                    <img className={`mx-auto d-block ${planYourBookStyles.heroImg}`} src={ heroImg } alt="" />
                                </Col>
                            </Row>
                        </Container>
                    </Row>
                </Container>
                <Container>
                    <Row className="m-0 px-0">
                        <Col className="p-0">
                            <div className={planYourBookStyles.lineHolder}>
                                <Controller>
                                    <Scene triggerHook="0.25" duration={200}>
                                        <Tween from={{ height: "0%" }} to={{ height: "50%" }}>
                                            <div className={`d-md-none ${planYourBookStyles.line} ${planYourBookStyles.lineV} ${planYourBookStyles.lineVHalfTop} ${planYourBookStyles.lineMid} ${planYourBookStyles.lineBottom}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                                <Controller>
                                    <Scene triggerHook="0" duration={300}>
                                        <Tween from={{ width: "0%" }} to={{ width: "50%" }}>
                                            <div className={`d-md-none ${planYourBookStyles.line} ${planYourBookStyles.lineW} ${planYourBookStyles.lineWHalfRight} ${planYourBookStyles.lineBottom}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                                <Controller>
                                    <Scene triggerHook="0.425" duration={270}>
                                        <Tween from={{ height: "0%" }} to={{ height: "50%" }}>
                                            <div className={`d-none d-md-block ${planYourBookStyles.line} ${planYourBookStyles.lineV} ${planYourBookStyles.lineVHalfTop} ${planYourBookStyles.lineRight} ${planYourBookStyles.lineBottom}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                                <Controller>
                                    <Scene triggerHook="0.15" duration={250}>
                                        <Tween from={{ width: "0%" }} to={{ width: "100%" }}>
                                            <div className={`d-none d-md-block ${planYourBookStyles.line} ${planYourBookStyles.lineW} ${planYourBookStyles.lineRight} ${planYourBookStyles.lineBottom}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                            </div>
                            <Row className="flex-column flex-md-row align-items-center py-5">
                                <Col xs="auto" md={{span:"auto", order:2}} className="mb-1 mb-md-0">
                                    <div className={planYourBookStyles.iconHolder}>
                                        <Icon.Eye className={planYourBookStyles.icon} />
                                    </div>
                                </Col>
                                <Col md={{order:1}} className="text-center text-md-right">
                                    <h2 className="headingSmall highlight">
                                        Read the starter or instructions
                                    </h2>
                                    <p>
                                        It’s all ready for you, but of course you can adjust it to make it the best fit for your class. Most books allow you to completely adapt the project to meet your pupils’ needs. You can also set the instructions your pupils will see on their screens as they write.
                                    </p>
                                </Col>
                                <Col xs={{}} md={7} className="ml-n6 mt-2 ml-sm-0 m-md-0">
                                    <img className={`mx-sm-auto mx-md-0 d-block ${planYourBookStyles.image1}`} src={storyStartImg} alt="" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="m-0 px-0">
                        <Col className="p-0">
                            <div className={planYourBookStyles.lineHolder}>
                                <Controller>
                                    <Scene triggerHook="0.3" duration={275}>
                                        <Tween from={{ height: "0%" }} to={{ height: "100%" }}>
                                            <div className={`${planYourBookStyles.line} ${planYourBookStyles.lineV} ${planYourBookStyles.lineLeft}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                                <Controller>
                                    <Scene triggerHook="0" duration={300}>
                                        <Tween from={{ width: "0%" }} to={{ width: "50%" }}>
                                            <div className={`${planYourBookStyles.line} ${planYourBookStyles.lineW} ${planYourBookStyles.lineBottom}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                            </div>
                            <Row className="align-items-center py-5">
                                <Col className="p-0">
                                    <Row className="flex-column flex-sm-row m-0 align-items-sm-center m-0">
                                        <Col xs="auto">
                                            <div className={planYourBookStyles.iconHolder}>
                                                <Icon.Edit3 className={planYourBookStyles.icon} />
                                            </div>
                                        </Col>
                                        <Col className="text-left light-bg py-1 py-sm-0">
                                            <h2 className="headingSmall highlight">
                                                Go Write!
                                            </h2>
                                            <p>
                                                Your class completes the writing task. You can provide feedback to them digitally using BoomWriter UK to support their work or reward their efforts!
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="mr-n4 m-sm-0 text-center">
                                    <img className={planYourBookStyles.image2} src={feedbackImg} alt="" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="m-0 px-0">
                        <Col className="p-0">
                            <div className={planYourBookStyles.lineHolder}>
                                <Controller>
                                    <Scene triggerHook="0.4" duration={600}>
                                        <Tween from={{ height: "0%" }} to={{ height: "100%" }}>
                                            <div className={`${planYourBookStyles.line} ${planYourBookStyles.lineV} ${planYourBookStyles.lineVFull} ${planYourBookStyles.lineMid}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                            </div>
                            <Row className="py-5">
                                <Col className="p-0 light-bg">
                                    <Row className="mx-0 flex-column justify-content-center align-items-center">
                                        <Col xs="auto" className="mb-1">
                                            <div className={planYourBookStyles.iconHolder}>
                                                <Icon.Star className={planYourBookStyles.icon} />
                                            </div>
                                        </Col>
                                        <Col md={9} lg={7} xl={6} className="text-center mx-auto">
                                            <h2 className="headingSmall highlight">
                                                Starcast
                                            </h2>
                                            <p>
                                                Pupils use the Starcast tool (peer assessment of other pupils’ anonymised work). This encourages your pupils to spend time reading others’ writing. They are asked to rate the work on how much they enjoyed reading it.
                                            </p>
                                        </Col>
                                        <Col className="text-center mt-2">
                                            <img className={planYourBookStyles.image3} src={starcastingImg} alt="" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="m-0 px-0">
                        <Col className="p-0">
                            <div className={planYourBookStyles.lineHolder}>
                                <Controller>
                                    <Scene triggerHook="onCenter" duration={100}>
                                        <Tween from={{ width: "0%" }} to={{ width: "50%" }}>
                                            <div className={`${planYourBookStyles.line} ${planYourBookStyles.lineW} ${planYourBookStyles.lineWHalfRight} ${planYourBookStyles.lineTop}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                                <Controller>
                                    <Scene triggerHook="0.4" duration={300}>
                                        <Tween from={{ height: "0%" }} to={{ height: "100%" }}>
                                            <div className={`${planYourBookStyles.line} ${planYourBookStyles.lineV} ${planYourBookStyles.lineLeft}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                                <Controller>
                                    <Scene triggerHook="0.05" duration={250}>
                                        <Tween from={{ width: "0%" }} to={{ width: "50%" }}>
                                            <div className={`${planYourBookStyles.line} ${planYourBookStyles.lineW} ${planYourBookStyles.lineBottom}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                            </div>
                            <Row className="align-items-center py-5">
                                <Col className="p-0">
                                    <Row className="flex-column flex-sm-row align-items-sm-center m-0">
                                        <Col xs="auto">
                                            <div className={planYourBookStyles.iconHolder}>
                                                <Icon.Award className={planYourBookStyles.icon} />
                                            </div>
                                        </Col>
                                        <Col className="text-left light-bg py-1 py-sm-0">
                                            <h2 className="headingSmall highlight">
                                                Celebrate Winner
                                            </h2>
                                            <p>
                                                Celebrate the winner and reward all of the writers with our reward system that rewards pupils for their efforts.
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="mr-n4 m-sm-0 text-md-right">
                                    <img className={planYourBookStyles.image4} src={winnerImg} alt="" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="m-0 px-0">
                        <Col className="p-0">
                            <div className={planYourBookStyles.lineHolder}>
                                <Controller>
                                    <Scene triggerHook="0.4" duration={300}>
                                        <Tween from={{ height: "0%" }} to={{ height: "100%" }}>
                                            <div className={`${planYourBookStyles.line} ${planYourBookStyles.lineV} ${planYourBookStyles.lineMid}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                            </div>
                            <Row className="pt-10">
                                <Col className="p-0 light-bg">
                                    <Row className="flex-column justify-content-center align-items-center mx-0">
                                        <Col xs="auto" className="mb-1">
                                            <div className={planYourBookStyles.iconHolder}>
                                                <Icon.Repeat className={planYourBookStyles.icon} />
                                            </div>
                                        </Col>
                                        <Col md={9} lg={7} xl={6} className="text-center mx-auto pb-1">
                                            <h2 className="headingSmall highlight">
                                                Repeat as required
                                            </h2>
                                            <p>
                                                Repeat the BoomWriter UK process until your book is complete. Some books contain multiple chapters of writing; some are single pieces of work.
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="m-0 px-0">
                        <Col className="p-0">
                        <div className={planYourBookStyles.lineHolder}>
                                <Controller>
                                    <Scene triggerHook="0.5" duration={300}>
                                        <Tween from={{ height: "0%" }} to={{ height: "50%" }}>
                                            <div className={`${planYourBookStyles.line} ${planYourBookStyles.lineV} ${planYourBookStyles.lineMid}`}></div>
                                        </Tween>
                                    </Scene>
                                </Controller>
                            </div>
                            <Row className="py-5 pt-10">
                                <Col className="p-0 light-bg">
                                    <Row className="flex-column justify-content-center align-items-center mx-0">
                                        <Col xs="auto" className="mb-1">
                                            <div className={planYourBookStyles.iconHolder}>
                                                <Icon.Users className={planYourBookStyles.icon} />
                                            </div>
                                        </Col>
                                        <Col md={9} lg={7} xl={6} className="text-center mx-auto">
                                            <h2 className="headingSmall highlight">
                                                Celebration Assembly
                                            </h2>
                                            <p>
                                                Receive the books ready for a celebration assembly. Each book is personalised to include a piece of work from that child, regardless whether they win a vote or not. Every child gets to experience the thrill of seeing their writing in print.
                                            </p>
                                        </Col>
                                        <Col className="mt-2 text-center">
                                            <img className={planYourBookStyles.image5} src={booksImg} alt="" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Layout>
        </>
    )
}

export default PlanYourBookPage